/**
 * Data for Gas Form
 */

export const FUELTYPES = [
  ['reg', 'Regular'],
  ['mid', 'Mid Grade'],
  ['pre', 'Premium'],
  ['diesel', 'Diesel'],
]

export const DISTANCES = [
  [1, "1 mile"],
  [2, "2 miles"],
  [3, "3 miles"],
  [4, "4 miles"],
  [5, "5 miles"],
  [6, "6 miles"],
  [7, "7 miles"],
  [8, "8 miles"],
  [9, "9 miles"],
  [10, "10 miles"],
  [11, "11 miles"],
  [12, "12 miles"],
  [13, "13 miles"],
  [14, "14 miles"],
  [15, "15 miles"],
  [16, "16 miles"],
  [17, "17 miles"],
  [18, "18 miles"],
  [19, "19 miles"],
  [20, "20 miles"],
  [21, "21 miles"],
  [22, "22 miles"],
  [23, "23 miles"],
  [24, "24 miles"],
  [25, "25 miles"],
  [26, "26 miles"],
  [27, "27 miles"],
  [28, "28 miles"],
  [29, "29 miles"],
  [30, "30 miles"],
  [31, "31 miles"],
  [32, "32 miles"],
  [33, "33 miles"],
  [34, "34 miles"],
  [35, "35 miles"],
  [36, "36 miles"],
  [37, "37 miles"],
  [38, "38 miles"],
  [39, "39 miles"],
  [40, "40 miles"],
  [41, "41 miles"],
  [42, "42 miles"],
  [43, "43 miles"],
  [44, "44 miles"],
  [45, "45 miles"],
  [46, "46 miles"],
  [47, "47 miles"],
  [48, "48 miles"],
  [49, "49 miles"],
  [50, "50 miles"]
]